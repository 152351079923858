import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter,Routes,Route,useLocation} from 'react-router-dom';
import AppRouting from "./services/routing";
import { useState } from "react";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log('does this go twice, also?')
export const UserContext = React.createContext(null);

const Idx=()=>{
    const [authorized, setAuthorized]=useState(false);
    const [authKey, setAuthKey]=useState('');
    const [refreshKey, setRefreshKey]=useState('');
  
    return(
        <UserContext.Provider value={{authKey: authKey, setAuthKey: setAuthKey, refreshKey: refreshKey, setRefreshKey: setRefreshKey, authorized: authorized, setAuthorized: setAuthorized}}>
            <BrowserRouter>
                <AppRouting/>
            </BrowserRouter>
        </UserContext.Provider>   
    )
}
root.render(<Idx/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
