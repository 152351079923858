export async function getToken(code, setAuthKey, setRefreshKey){

  var urlencoded = new URLSearchParams();
  console.log('Token: '+code);
  console.log(btoa("2jr76cl8ru783le61d7vuq3jia"+":"+"1v4dja411poishnp3tqkqrb22i580iuprmireask2ir310v05aof"));
  console.log(btoa(process.env.REACT_APP_CLIENTID+":"+process.env.REACT_APP_CLIENTSECRET));
  urlencoded.append("grant_type", "authorization_code");
  ////urlencoded.append("client_id", "15orn3rlpkss4d2d7opu0c9jhk");
  console.log(process.env.REACT_APP_BASEURL)
  urlencoded.append("redirect_uri", process.env.REACT_APP_BASEURL);
  urlencoded.append("code", code);

  return fetch(process.env.REACT_APP_COGNITO+'token',{
      method: 'POST',
    headers: {
        'Content-type': "application/x-www-form-urlencoded",
        "Cookie": "XSRF-TOKEN=74c4608d-6ea9-46ac-9bff-7930275d4db6",
        'Authorization': "Basic " + btoa(process.env.REACT_APP_CLIENTID+":"+process.env.REACT_APP_CLIENTSECRET)
    },
    body: urlencoded,
    redirect: 'follow'
  }).then((response) => {
    if(response.status==401){
      console.warn('Received 401!!!')
      setAuthKey("");
      setRefreshKey("");
      //global.authKey=""
      //global.refreshKey=""

    }else if(response.status==400){
      console.warn('Received a 400!!!')
      return {}
    }else{
      console.warn(response);
      return response.json()
    }
  })
  .then((json)=>{
    //console.warn('showing data')
    console.warn(json);
    setAuthKey(json.id_token)
    setRefreshKey(json.refresh_token)
    //global.authKey=json.id_token
    //global.refreshKey=json.refresh_token
    return json
  })
  .catch((error) => {
    console.log('here comes error');
    console.error(error);
    return error.text;
  })


}

function requestHeaders() {
  return {
    Accept: "application/json",
    "Content-type": "application/json",
  };
}

export async function selectListValues(listType) {
  console.log(listType, "list");
  return fetch(
    process.env.REACT_APP_API +
      "/selectlistvalues?listName=" +
      listType +
      "&updated=2020-01-01",
    {
      method: "GET",
      headers: {
        ...requestHeaders()
        //Cookie: GET.token
      },
    }
  )
    .then((response) => {
      if (response.status == 200) {
        return response.json();
      } else {
        console.warn("Error might be starting here");
        throw new Error(
          "Response from Select List Values" +
            response.headers["content-type"] +
            response
        );
      }
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
}


export async function getUserPermissions(authKey, setAuthKey, refreshKey) {
  //console.log(CLIENT_ID)
      console.log('PERMISSIONS KEY',authKey)
      var myHeaders = new Headers();
      const fetchOptions = {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "User-Agent": "FlowConnect",
            "Accept": "application/json",
//            "Accept-Encoding": "gzip, deflate, br",
            "Connection": "keep-alive",
            'Authorization': 'Bearer '+authKey

        },
          //body: JSON.stringify({deviceId: global.deviceID, tableName: tableName, action: action, tableRecord: tableRecord})
      }            
      return fetch(process.env.REACT_APP_API+`/userpermissions`, fetchOptions).then(
          (response)=>{
              console.log('getting a response for UserPermissions',response)
              if(response.status===401){
                refreshToken(refreshKey, setAuthKey).then(
                  refreshResponse=>{
                      if(refreshResponse==="success"){
                        return getUserPermissions(authKey, setAuthKey, refreshKey).then(reSaveResponse=>{return reSaveResponse})
                      }
                  }
                )
              }else{
                if(response.status!==200){
                    if(response.status===500){
                        throw {status: 500}
                    }
                    throw response
                }
                return response.json();
              }
          }
      ).then(
          (json)=>{
              return {data: json.appPermissions}
          }
      ).catch((error)=>{        
          console.log(error.message);
      })

  
}

 export async function refreshToken(refreshKey,setAuthKey){
    console.log('TOKEN REFRES')
    console.log(refreshKey);
    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "refresh_token");
    //urlencoded.append("client_id", "15orn3rlpkss4d2d7opu0c9jhk");
    urlencoded.append("refresh_token", refreshKey);
    //urlencoded.append("client_secret","72nbqdi9jn5npiovdigebuq5170uetakph7aveipp1flms74lca")

    return fetch(process.env.REACT_APP_COGNITO+'token',{
        method: 'POST',
      headers: {
          "Cookie": "XSRF-TOKEN=74c4608d-6ea9-46ac-9bff-7930275d4db6",
          'Content-type': "application/x-www-form-urlencoded",
          //'refresh_token': global.refreshKey
          'Authorization': "Basic " + btoa(process.env.REACT_APP_CLIENTID+":"+process.env.REACT_APP_CLIENTSECRET)
      },
      body: urlencoded,
      redirect: 'follow'
    }).then((response) => {
      console.warn(response);
      if(response.status==401 || response.status==400){
        window.location.replace(process.env.REACT_APP_COGNITO+'authorize?response_type=code&client_id='+process.env.REACT_APP_CLIENTID+'&redirect_uri='+process.env.REACT_APP_BASEURL+'&state=STATE&scope=openid+email+profile');
       }else{
        return response.json()
       }
    })
    .then((json)=>{
      //console.warn('showing data')
      setAuthKey(json.id_token)
      return "success"
    })
    .catch((error) => {
      console.error(error);
      return error.text;
    })    
  }

  export function parseJwt (token) {
    if(token!=""){
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }else{
      return {}
    }

    
  }

  export function setPathCookie(pathname){
    document.cookie='tempPath=;  expires=Fri, 31 Dec 1999 23:59:59 GMT;';
    document.cookie='tempPath='+pathname+'; path=/';

  }

  export async function getHistory(authKey,userId){
    const jsonBody={
      'userId': userId
    };  
    return fetch(process.env.REACT_APP_API+'/scanhistory',{
      method: 'POST',
      headers: {...requestHeaders(),Authorization: 'Bearer '+authKey},
      body: JSON.stringify(jsonBody)
    }).then((response) => {
      if(response.status!==200){
        if(response.status===500){
            //--//dispatch(changeQueueSemaphore('RED'))
            throw {status: 500}
        }
        if (response.status === 401){
          window.location.replace(window.location.href)
        }

        throw response
      }else if (response.headers.get('content-type').match(/application\/json/)) {
        return response.json();
      }else{
        console.warn('Error might be staring here');
        return 'Response from ScanHistory'+response.headers.get('content-type')+JSON.stringify(response.text());
      }
    })
    .then((json)=>{
      return json 
    })
    .catch((error) => {
      if(error.status===401){
        //--//dispatch(changeQueueSemaphore('RED')) 
      
        /*
        May need to implement this throughout....
        
        return refreshAccessToken().then(result => {
            console.log('went for a refresh Token')
            console.warn(result)
            if(result && result.id_token) { 
                console.log('came in here to updateTokenAction');
                dispatch(updateTokenAction(result.id_token))
                //--//setTimeout(()=>dispatch(changeQueueSemaphore('GREEN')),2000)
                return new Promise((resolve, reject)=>{
                    setTimeout(()=>{resolve(ScanHistory(userId))},100)
                })
            }
            return Promise.reject('No Token')
          }).catch((error)=>{
            console.warn('Failed to REFRESH');
            Alert.alert("Your login has expired and could not be refreshed.  Please login to obtain search history.")
            dispatch(signout(false));

          })
          */
      }else{
        console.error(error);
        return error.text;
      }
    })      
  }

// import React, { useEffect, useState } from "react";
//update to rid master of iframe didn't work
/*
function Fetcher() {
  const [data, setData] = useState("");
  console.log(data, "data");
  useEffect(() => {
    const iframe = document.createElement("iframe");
    console.log(iframe);
    iframe.src = "https://testlightnin.bigmachines.com/rest/v14/accounts";
    

    const handleLoad = () => {

    //   const iframeContent = iframe.contentDocument?.documentElement?.innerHTML;
    //   console.log(iframeContent, "iframeContent");

      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const preElement = iframeDocument.querySelector("pre");
      const errorMessage = preElement ? preElement.textContent : null;

      console.log("Error Message:", errorMessage);

      if (isLoggedIn(iframeContent)) {
        navigate("https://testlightnin.bigmachines.com/commerce/display_company_profile.jsp");
      } else {
            window.location.href =
              "https://testlightnin.bigmachines.com/commerce/display_company_profile.jsp";
      }
    };

    iframe.addEventListener("load", handleLoad);

    document.body.appendChild(iframe);

    return () => {
      iframe.removeEventListener("load", handleLoad);
      document.body.removeChild(iframe);
    };
  }, [navigate]);

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
}

function isLoggedIn(iframeContent) {
  return (
    !iframeContent?.includes("Access Denied") &&
    iframeContent?.includes("something from that url that is unique when properly logged in")
  );
}
*/


// import React, { useEffect } from "react";
// import { useNavigate } from "react-router-dom";

// export default function Fetcher() {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const iframe = document.createElement("iframe");
//     console.log(iframe);
//     iframe.src = "https://testlightnin.bigmachines.com/rest/v14/accounts/1011835";

//     const handleLoad = () => {
//       iframe.contentWindow.postMessage("getContent", "*");
//     };

//     const handleMessage = () => {
//      if (isLoggedIn(iframeContent)) {
//           navigate(
//             "https://testlightnin.bigmachines.com/commerce/display_company_profile.jsp"
//           );
//         } else {
//           window.location.href =
//             "https://testlightnin.bigmachines.com/commerce/display_company_profile.jsp";
//         }
//     };

//     iframe.addEventListener("load", handleLoad);
//     window.addEventListener("message", handleMessage);

//     document.body.appendChild(iframe);

//     return () => {
//       iframe.removeEventListener("load", handleLoad);
//       window.removeEventListener("message", handleMessage);
//       document.body.removeChild(iframe);
//     };
//   }, [navigate]);

//   return (
//     <div>
//       <h1>Loading...</h1>
//     </div>
//   );
// }

// function isLoggedIn(iframeContent) {
//   return (
//     !iframeContent?.includes("Access Denied") &&
//     iframeContent?.includes(
//       "something from that URL that is unique when properly logged in"
//     )
//   );
// }