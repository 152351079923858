import React, { useEffect, useState } from "react";


function FetchTest() {
    const [returnData,setReturnData]=useState("")
    useEffect(() => {
        const cookCookie=''
        fetch('https://spxflow-com-test.azurewebsites.net/partner-portal/', {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            
//              Cookie: cookCookie
            }
          }).then(
                (response)=>{
                    return(response.json())
                }
            ).then(
                (json)=>{
                    setReturnData(json);
                }
            ).catch((e)=>{setReturnData('ERROR'+e)})
    },[]);
    return(
        <div>Data Returned: {returnData}</div>
    )
}

export default FetchTest;


