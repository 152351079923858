import React from "react";
import "../gearbox.css";
import logo from "../assets/logo_Image_10.bmp";
import logo_Image_1 from "../assets/logo_Image_1.bmp";
import logo_Image_2 from "../assets/logo_Image_2.bmp";
import logo_Image_3 from "../assets/logo_Image_3.bmp";
import logo_Image_4 from "../assets/logo_Image_4.bmp";
import logo_Image_5 from "../assets/logo_Image_5.bmp";
import logo7 from "../assets/logo_Image_7.bmp";
import logo6 from "../assets/logo_Image_6.bmp";
import logo8 from "../assets/logo_Image_8.bmp";
import logo9 from "../assets/logo_Image_9.bmp";

import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function GearBox() {
  const [data, setData] = useState({});
  const { sId } = useParams();
  const [updateDate, setUpdateDate]=useState(Date.parse('01 Jan 2023 00:00:00 GMT'));
  console.log('ID:',sId)
  useEffect(() => {
    const jsonBody = JSON.stringify({ sId: sId });
    console.log('body:',jsonBody)
    fetch(process.env.REACT_APP_API_URL+"gboxInventory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonBody,
    })
    .then((response) => {
      console.log('got response', response)
      if (response.status === 404) {
        throw new Error("Not Found");
      }
      return response.json();
    })
    .then((data) => {
      console.log(data);
      setData(data);
      console.log('Date Updated:',data.stockUpdate)
      setUpdateDate(new Date(data.stockUpdate));
    })
    .catch((error) => {  
      if (error.message === "Not Found") {
        console.log("404 error: Response not found");
      } else {
        console.log(error);
      }
    });
  }, [sId]);
  
  const series70=["71","72","73","74","75","76","77"];
  const ratios70=["6.07-6.43","7.34-7.75","9.06-9.5","10.97-11.69","13.6-14.5","16.7-17.5","20.5-21.5","25.33-26.1","30.65-32.1","37.85-38.2"]
  const series80=["81","82","83","84","85","86","87"]
  const ratios80=["6.07-6.43","7.34-7.75","9.06-9.5","10.97-11.69","13.6-14.5","16.7-17.5","20.5-21.5","25.33-26.1","30.65-32.1","37.85-38.2"]
  const series10=["14","15","16","17"]
  const ratios10=["14.06-14.25","17.06-17.31","20.78","25.962"]
  const typeXL=["Elec","Air"]
  const matlXL=["STL","SS"]
  const ratiosXL=["9.13","11.41","14.4","17.42","20.74","26","26-1200RPM IN"]
  const series700=[{"780/880": ["RL","RR","880"]},{"780/880": ["RL","RR","880"]},{"781/881": ["RL","RR","880"]},{"781/881": ["RL","RR","880"]},{"782/882": ["RL","RR","880"]},{"783/883": ["RR","880"]}]
  const series7001=[{"780/880a": ["RL","RR","880"]},{"780/880b": ["RL","RR","880"]},{"781/881a": ["RL","RR","880"]},{"781/881b": ["RL","RR","880"]},{"782/882": ["RL","RR","880"]},{"783/883": ["RR","880"]}]
  const ratios700={ doubleReduction: ["7.679","9.064","11.7 - 11.77","13.81 - 13.9","17.359 - 17.46","21.075 - 21.2","25.63 - 25.79","31.96", ], tripleReduction: ["21.43 - 21.56","25.838 - 25.99","31.38 - 31.57","38.8 - 39.31","47.419 - 47.71","58.46 - 58.88","71.02 - 71.45"]}
  const series500=["503","504","505","506","507","508"]
  const series600=["603","604","605","606","607","608"]
  const ratios500=["9.1 - 9.4","11 - 11.7","13.6 - 14.4","16.7 - 17.4","16.7 SPECIAL 30HP, 100 RPM","20.8 - 21.5","20.8 SPECIAL 25&30HP, 84 RPM","25.3 - 25.6","30.7 - 32.1","37.9 - 38.2"]
  const seriesRSE=["108","208","308"]
  const ratiosRSE=["6.08"]    
  const series3850={"3853": [["11.4:1","R30F00-00000"],["20.9:1","R30J00-00000"],["25.6:1","R30K00-00000"],["31.4:1","R30L00-00000"],["70.6:1","R30P00-00000"]],
                    "3855": [["20.9:1","R50J00-00000"],["25.6:1","R50K00-00000"],["31.4:1","R50L00-00000"],["70.6:1","R50P00-00000"]],
                    "3857": [["31.4:1","R70L00-00000"],["38.4:1","R70M00-00000"]],
                    "3859": [["38.4:1","R90M00-00000"]],
                    "3861": [["25.6:1","RK0K00-00000"]]}
  const tableData = [
                      {
                        series: "70/80Series",
                        items: [
                          { size: "71/81", inventory: "Rochester Service" },
                          { size: "72/82", inventory: "Houston Service" },
                          { size: "73/83", inventory: "Rochester Service" },
                          { size: "74/84", inventory: "Houston Service" },
                          { size: "75/85", inventory: "Rochester Service" },
                          { size: "76/86", inventory: "Houston Service" },
                          { size: "77/87", inventory: "Rochester Service" },
                        ]
                      },
                      {
                        series: "Series 10",
                        items: [
                          { size: "14/15", inventory: "Rochester Service" },
                          { size: "16", inventory: "Houston Service" },
                          { size: "17", inventory: "Rochester Service" },
                        ]
                      },
                      {
                        series: "700/800",
                        items: [
                          { size: "780/880", inventory: "Houston Service" },
                          // { size: "780/880b", inventory: "Houston Service" },
                          { size: "781/881", inventory: "Rochester Service" },
                          // { size: "781/881b", inventory: "Rochester Service" },
                          { size: "782/882", inventory: "Houston Service" },
                          { size: "783/883", inventory: "Rochester Service" },
                        ]
                      },
                      {
                        series: "Raven",
                        items: [
                          { size: "3853", inventory: "Palmyra" },
                          { size: "3855", inventory: "Palmyra" },
                          { size: "3857", inventory: "Palmyra" },
                          { size: "3859", inventory: "Palmyra" },
                          { size: "3861", inventory: "Palmyra" },
                        ]
                      },
                      {
                        series: "RSE(S)",
                        items: [
                          { size: "108", inventory: "Rochester Service" },
                          { size: "208", inventory: "Houston Service" },
                          { size: "308", inventory: "Rochester Service" },
                        ]
                      },
                      {
                        series: "XL",
                        items: [
                          //{ size: "Xl", inventory: "Houston Service" },
                          { size: "Compact CMX", inventory: "Modesto Service" },
                        ]
                      },
                      {
                        series: "500/600",
                        items: [
                          { size: "503/603", inventory: "Delavan Service" },
                          { size: "504/604", inventory: "Delavan Service" },
                          { size: "505/605", inventory: "Delavan Service" },
                          { size: "506/606", inventory: "Delavan Service" },
                          { size: "507/607", inventory: "Delavan Service" },
                          { size: "508/608", inventory: "Delavan Service" },
                        ]
                      },
                    ];


  return (
    <>
      <div className="outline">
        <div className="main-header-inv">
          <div className="header-container">
            <div className="header-left">
              <img src={logo} title="Logo" alt="" />
            </div>
            <div className="header-right">
              <h2>RUSH - GEARBOX ON DEMAND</h2>
            </div>
             </div>
           <div className="left-container">
            <div className="left-section">
              For <span className="red-text">OUT OF STOCK,</span> check with the
              Northeast or Southwest Service Center for the lead time available.  See bottom of page for which center to contact.
              Please see sales data book, section 30, subsection 3.<br/>
              If <span> Limited stock </span> or <span>Available </span> 
              Lead Times are as follows:
              <br/> 70, 80, RSE(S), S10, Raven = 2 Days
              <br/>700/800 = 7 Days{" "}
            </div>
            <div className="right-section">
            <div className="avaliability-inventory">
                <div className="availability">
                  <h4>Availability</h4>
                </div>
                <div className="inventory-updated">
                  <span>Inventory updated on {
                    new Date(data.stockUpdate).toLocaleDateString()
                  //updateDate.toLocaleDateString===undefined?"":updateDate.toLocaleDateString()
                  }</span>
                </div>
              </div>
              <div className="table-div">
                <table>
                  <thead>
                    <tr>
                      <th className="legend">LEGEND</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="not-available white-text">
                      <td>NOT AVAILABLE</td>
                    </tr>
                    <tr className="out-of-stock white-text">
                      <td>OUT OF STOCK</td>
                    </tr>
                    <tr className="limited-stock black-text">
                      <td>LIMITED STOCK</td>
                    </tr>
                    <tr className="available white-text">
                      <td>AVAILABLE</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="secondrow">
          <div className="series70">
            <div className="series70-machine">
              <img src={logo_Image_1} alt="70 series machine"></img>
            </div>
            <div className="series70-table">
              <table>
                <thead>
                  <tr>
                    <th colSpan="8">70 series - RL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="no-border">Ratio</th>
                    {
                      series70.map((item)=>{
                        return(<th>{item}</th>)
                      })
                    }
                  </tr>
                  {
                    ratios70.map((ratio)=>{
                      return(<tr key={'70s'-ratio}>
                        <td className="no-border">{ratio}</td>
                      {
                        series70.map((item)=>{
                          return(<td key={item+'-'+ratio} className={
                              data[item+'|'+ratio]==undefined?"not-available":
                              data[item+'|'+ratio]<=0?"out-of-stock":
                              data[item+'|'+ratio]==1?"limited-stock":
                              data[item+'|'+ratio]>=2?"available":""
                            }>
                            {data[item+'|'+ratio]}
                          </td>)
                        })
                      }
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="series80">
            <div className="series80-table">
              <table>
                <thead>
                  <tr>
                    <th colSpan="8">80 series</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="no-border">Ratio</th>
                    {
                      series80.map((item)=>{
                        return(<th>{item}</th>)
                      })
                    }
                  </tr>
                  {
                    ratios80.map((ratio)=>{
                      return(<tr key={'80s'-ratio}>
                        <td className="no-border">{ratio}</td>
                      {
                        series80.map((item)=>{
                          return(<td key={item+'-'+ratio} className={
                              data[item+'|'+ratio]==undefined?"not-available":
                              data[item+'|'+ratio]<=0?"out-of-stock":
                              data[item+'|'+ratio]==1?"limited-stock":
                              data[item+'|'+ratio]>=2?"available":""
                            }>
                            {data[item+'|'+ratio]}
                          </td>)
                        })
                      }
                      </tr>)
                  })}
                </tbody>
              </table>
            </div>
            <div className="series80-machine">
              <img src={logo_Image_2} alt="80 series machine"></img>
            </div>
          </div>
        </div>

        <div className="thirdrow">
          <div className="thirdrow-chirld1">
            <div className="series10">
              <div className="series10-machine">
                <img src={logo_Image_3} style ={{width:"190px"}} alt="10 series machine"></img>
              </div>
              <div className="series10-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="5">10 series</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th className="no-border">Ratio</th>
                    {
                      series10.map((item)=>{
                        return(<th>{item}</th>)
                      })
                    }
                  </tr>
                  {
                    ratios10.map((ratio)=>{
                      return(<tr key={'10s'-ratio}>
                        <td className="no-border">{ratio}</td>
                      {
                        series10.map((item)=>{
                          return(<td key={'S'+item+'-'+ratio} className={
                              data['S'+item+'|'+ratio]==undefined?"not-available":
                              data['S'+item+'|'+ratio]<=0?"out-of-stock":
                              data['S'+item+'|'+ratio]==1?"limited-stock":
                              data['S'+item+'|'+ratio]>=2?"available":""
                            }>
                            {data['S'+item+'|'+ratio]}
                          </td>)
                        })
                      }
                      </tr>)
                  })}
                </tbody>
                </table>
              </div>
            </div>
            {
            /*  Service Request  96938 asked for this section to be removed.

            <div className="fourthrow">
              <div className="xl-product">
                <div className="xl-product-machine">
                  <img src={logo_Image_5} alt="xl-product machine"></img>
                </div>
                <div className="xl-product-table">
                  {
                    // <div className="xl-product-table-heading" >
                    //<h4>XL-Production Line</h4>
                    //</div> 
                  }
                  <table>
                    <thead>
                      <tr>
                        <th colSpan="5">XL-Production Line</th>
                      </tr>

                      <tr>
                        <th rowSpan="1" className="no-border">
                          Motor Type
                        </th>
                        <th colSpan="2">Elec</th>
                        <th colSpan="2">Air</th>
                      </tr>

                      <tr>
                        <th className="no-border" rowSpan="1">
                          Output Shaft Material
                        </th>
                        <th colSpan="1">STL</th>
                        <th colSpan="1">SS</th>
                        <th colSpan="1">STL</th>
                        <th colSpan="1">SS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className="no-border">Ratio</th>
                        <th colSpan="1"></th>
                        <th colSpan="1"></th>
                        <th colSpan="1"></th>
                        <th colSpan="1"></th>
                      </tr>
                      {
                        ratiosXL.map((ratio)=>{
                          return(<tr key={'10s'-ratio}>
                            <td className="no-border">{ratio}</td>
                          {
                            typeXL.map((item)=>{
                              return matlXL.map((mtl)=>{
                                return(<td key={'XL'+item+mtl+ratio} class={
                                    data['XL'+item+mtl+'|'+ratio]==undefined?"not-available":
                                    data['XL'+item+mtl+'|'+ratio]<=0?"out-of-stock":
                                    data['XL'+item+mtl+'|'+ratio]==1?"limited-stock":
                                    data['XL'+item+mtl+'|'+ratio]>=2?"available":""
                                  }>
                                  {data['XL'+item+mtl+'|'+ratio]}
                                </td>)                                
                              })
                            })
                          }
                          </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            */
            }
          </div>

          <div className="raven">
              <div style={{textAlign: 'center'}}>
                Epicor Data Updated on {new Date(data.epicorUpdate).toLocaleDateString()}
              </div>

            <div className="raven-table">
              <table style={{ width: "90%" }}>
                <thead>
                  <tr>
                    <th colSpan="8">RAVEN</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Availability</th>
                    <th>Ratio</th>
                    <th>P/N</th>
                    <th colSpan="5">Size</th>
                  </tr>

                  {Object.keys(series3850).map((key)=>(
                      <>
                    
                    
                      {
                        series3850[key].map((ratio,idx)=>{
                          return (<tr>
                                    <td key={key+ratio[0]} className={
                                      data[key+'|'+ratio[0]]==undefined?"not-available":
                                      data[key+'|'+ratio[0]]<=0?"out-of-stock":
                                      //data[key+'|'+ratio[0]]==1?"limited-stock":""
                                      data[key+'|'+ratio[0]]>=1?"available":""
                                    }>
                                      {data[key+'|'+ratio[0]]}

                                    </td>                            
                                    <td>{ratio[0]}</td>
                                    <td>{ratio[1]}</td>
                                    {idx==0?<th rowSpan={series3850[key].length}>{key}M-S</th>:<></>}
                                  </tr>);
                        })
                      
                      }
                    </>
                     )
                  )}
                  
                </tbody>
              </table>
            </div>
            <div className="raven-machine">
              <img src={logo_Image_4} alt="RAVEN"></img>
            </div>
          </div>
        </div>
      </div>
      <div >
        <div className="main-header">
          <div className="header-container">
          </div>
          <div className="container">          
            <div className="right-section">
            </div>
          </div>

             <div className="series800">
              <div className="series800-table ">
              <table>
                <thead>
                  <tr>
                    <th colSpan="19">700/800 series</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                  <td colSpan="2"></td>
                    {series700.map((item)=>{
                      return (<td colSpan="3">{Object.keys(item)[0]}</td>)
                    })}
                  </tr>
                  <tr>
                  <th rowSpan={ratios700.doubleReduction.length+1} className="vertical-header">Double Reduction</th>
                    <th className="no-border">Ratio</th>
                    {
                      series7001.map((item)=>{
                        return (
                          item[Object.keys(item)[0]].map((txt)=>{
                            return (<th>{txt.substring(0,1)=='R'?txt:Object.keys(item)[0].substring(4)}</th>)
                          })
                        )
                      })
                    }
                  </tr>
                   {/* <tr>
               <th rowSpan="7">Triple Reduction</th>
               </tr> */}
                  
                  {/* <th rowSpan="7">Double Reduction</th> */}
                  {ratios700.doubleReduction.map((item,idx) => (
                    <tr key={item}>
                      <td className="no-border">
                        {item}
                      </td>
                      {                      
                        series700.map((srs)=>{
                          return (
                            srs[Object.keys(srs)[0]].map((txt)=>{
                              const dataKey=txt.substring(0,1)=='R'?Object.keys(srs)[0].substring(0,4)+txt+'|'+item:Object.keys(srs)[0].substring(4,7)+'|'+item;
                              return (
                                <td key={dataKey} className={
                                  data[dataKey]==undefined?"not-available":
                                  data[dataKey]<=0?"out-of-stock":
                                  data[dataKey]==1?"limited-stock":
                                  data[dataKey]>=2?"available":""
                                }>
                                  {data[dataKey]}

                                </td>
                              )
                            })
                            
                          )
                        })
                      }
                    </tr>
                  ))}
                  <tr>
                  <th rowSpan={ratios700.tripleReduction.length+1} className="vertical-header">Triple Reduction</th>
                    
                  </tr>
                   
                  {ratios700.tripleReduction.map((item,idx) => (
                    <tr key={item}>
                      <td className={idx==0?"top-border":"no-border"}>
                        {item}
                      </td>
                      {                      
                        series700.map((srs)=>{
                          return (
                            srs[Object.keys(srs)[0]].map((txt)=>{
                              const dataKey=txt.substring(0,1)=='R'?Object.keys(srs)[0].substring(0,3)+txt+'|'+item:Object.keys(srs)[0].substring(4,7)+'|'+item;
                              return (
                                <td key={dataKey} className={
                                  data[dataKey]==undefined?"not-available":
                                  data[dataKey]<=0?"out-of-stock":
                                  data[dataKey]==1?"limited-stock":
                                  data[dataKey]>=2?"available":""
                                }>
                                  {data[dataKey]}

                                </td>
                              )
                            })
                            
                          )
                        })
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="both-series800-700-machine">
              <div className="series800-700-machine">
                <img src={logo7} alt="70 series machine"></img>
              </div>
              <div className="series800-700-machine">
                <img src={logo6} alt="70 series machine"></img>
              </div>
            </div>
          </div>
          <div className="secondrow">
            {
            /*    Service Request  96938 asked for this section to be removed. 
            <div className="series500-600">
              <div className="series500-600-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="8">500/600 series</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="no-border"></th>
                      {
                        series500.map((item)=>{
                          return(<th>{item}</th>)
                        })
                      }
                    </tr>
                    <tr>
                    <th className="no-border">Ratio</th>
                    {
                      series600.map((item)=>{
                        return(<th>{item}</th>)
                      })
                    }
                  </tr>
                  {
                        ratios500.map((ratio)=>{
                          return(<tr key={'10s'-ratio}>
                            <td className="no-border">{ratio}</td>
                          {
                            series500.map((item)=>{
                                return(<td key={'500'+item+ratio} class={
                                    data[item+'|'+ratio]==undefined?"not-available":
                                    data[item+'|'+ratio]<=0?"out-of-stock":
                                    data[item+'|'+ratio]==1?"limited-stock":
                                    data[item+'|'+ratio]>=2?"available":""
                                  }>
                                  {data[item+'|'+ratio]}
                                </td>)                                
                            })
                          }
                          </tr>)
                      })}

                  </tbody>
                </table>
              </div>
              <div className="series500-600-machine">
                <img src={logo8} alt="70 series machine"></img>
              </div>
            </div>
            */
            }

            <div className="rse-series80">
              <div className="rse-series80-table">
                <table>
                  <thead>
                    <tr>
                      <th colSpan="8">RSE(S)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="no-border">Ratio</th>
                      {
                        seriesRSE.map((item)=>{
                          return(<th>{item}</th>)
                        })
                      }
                    </tr>
                    {
                        ratiosRSE.map((ratio)=>{
                          return(<tr key={'10s'-ratio}>
                            <td className="no-border">{ratio}</td>
                          {
                            seriesRSE.map((item)=>{
                                return(<td key={item+ratio} class={
                                    data[item+'|'+ratio]=== undefined?"not-available":
                                    data[item+'|'+ratio]<=0?"out-of-stock":
                                    data[item+'|'+ratio]==1?"limited-stock":
                                    data[item+'|'+ratio]>=2?"available":""
                                  }>
                                  {data[item+'|'+ratio]}
                                </td>)                                
                            })
                          }
                          </tr>)
                      })}
                  </tbody>
                </table>
              </div>
              <div className="rse-machine">
                <img src={logo9} alt="80 series machine"></img>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    <div style={{border:"5px solid gray", width:"120%"}}>
      <h4 className="inv-header" >Who has the Inventory to build? This is where RFQs should be sent.</h4>

      <table className= "inv-table" style={{ width: '500px', margin: '0 auto' }}>
          <thead>
            <tr>
              <th style={{ borderTop: 'none', borderLeft: 'none' }}></th>
              <th>Size</th>
              <th>Inventory Location</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((seriesData, index) => (
              seriesData.items.map((item, itemIndex) => (
                <tr key={index + '-' + itemIndex}>
                  {itemIndex === 0 && <td rowSpan={seriesData.items.length}>{seriesData.series}</td>}
                  <td>{item.size}</td>
                  <td>{item.inventory}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
    </div>
    </>
  );
}

export default GearBox;
