import { useEffect, useState, useContext } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../index";
// import "../App.css";
import "../serialSearch.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./header";
import { setPathCookie } from "../services/fetcher";
import { parseJwt } from "../services/fetcher";

export const SearchTable=({data,tableType='regular'})=>{
  console.log('Data provided',data)
  return(
  <div className="search-table">
    <table className="build-data">
      <thead>
        <tr className="build-row-header">
          <td className="build-table-header">Serial Number</td>
          {tableType=='regular'?<td className="build-table-header">Material Number</td>:<></>}
          <td className="build-table-header">Description</td>
          {tableType=='regular'?<td className="build-table-header">Brand</td>:<></>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => {
          if(index<10)return (
            <tr key={index} className="build-row-data">
              <td className="build-table-cell">
                <Link
                  className="serial-link"
                  to={`/serialData/${
                    item?.equipId
                  }`}>
                  {item?.sn}
                </Link>
              </td>
              {tableType=='regular'?<td className="build-table-cell">{item?.partNumber}</td>:<></>}
              <td className="build-table-cell">{item?.description}</td>
              {tableType=='regular'?<td className="build-table-cell">{item?.brand}</td>:<></>}
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>  
  )
}


function SerialSearch() {
  const [data, setData] = useState([]);
  const { searchInput, iframeContent, sId } = useParams();
  const navigate = useNavigate();
  var {pathname, search, hash} = useLocation();
  const { authKey, setAuthKey, refreshKey, setRefreshKey, authorized } = useContext(UserContext);
  console.log("AuthKey",authKey);
  const userEmail=parseJwt(authKey)
  
  function seriallookupData(){
    console.log('looking up serial data',searchInput,process.env.REACT_APP_API_URL,sId)
    fetch(process.env.REACT_APP_API+'/seriallookup', //"https://esalesserialsearch-api-stg.spxflow.com:8000/seriallookup",
     {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer "+authKey
      },
      body: JSON.stringify({ sn: searchInput, userId: userEmail['email']}),
    })
    .then((response) => {
      console.log('got response', response)
      if (response.status === 404) {
        throw new Error("Not Found");
      }
      if (response.status === 401){
        window.location.replace(window.location.href)
      }

      return response.json();
    })
    .then((data) => {
      console.log(data);
      setData(data)
    })
    .catch((error) => {  
      if (error.message === "Not Found") {
        console.log("404 error: Response not found");
      } else {
        console.log(error);
      }
    });
  }
    useEffect(() => {
      seriallookupData()
    }, [searchInput]);


  if(data?.length===1 && data?.status=="Success"){
    setPathCookie(`/serialData/${data[0]?.equipId}`);

    navigate(
      `/serialData/${data[0]?.equipId}`
    )
  }
  return (
    <>
    <Header/>
    <div className="multiple-equip" role="button" onClick={()=>{navigate('/search');}} ><h6>&lt;&lt;Back to Search</h6></div>

    <div className="main-table">

      {data.length === 0 && <h2>Loading...</h2>}
      {data.length > 0 && data[0]?.status === "Serial Number not found." && (
  
          <div>
            <h4 className="search-text">
              No results found for the term : "{searchInput}"
            </h4>
          </div>
      )}
      {data?.length > 0 && data?.length < 10 && data[0]?.status === "Success" && (
        <div className="search-main-table">
          <h4 className="search-text">
            {data?.length === 1 &&
              navigate(
                `/serialData/${data[0]?.equipId}`
              )}
          </h4>
          <div className="muliple-equip">
          <h4>
            Multiple Equipment records have been found for the term: {searchInput}
          </h4>
          </div>
          <SearchTable data={data}/>
        </div>
      )}
      { data[0]?.status === "Too many results for role" && (
        <div>
          <div className="search-text-header">
            <h4 className="search-text">Too Many results </h4>
          </div>
          <div className="search-text-head">
            <h5>
              Too many results were found. Please refine your search or contact
              us to assist in finding the equipment
            </h5>
          </div>
        </div>
      
      )}
    </div>
    </>
  );
}

export default SerialSearch;
