import {BrowserRouter,Routes,Route,useLocation, useNavigate, Router} from 'react-router-dom';
import AppRoutes from "./services/routing"
import React, {Component, useEffect, useState, useContext} from 'react';
import { getToken, getUserPermissions } from './services/fetcher';
// import GearBox from './components/gearBox'


function CheckCode() {
  const {pathname, search, hash} = useLocation()
  const params=new URLSearchParams(search);
  return (
      params.get('code')
  )
}

export const UserContext = React.createContext(null);


function App({path}) {

  //if(!authorized || authKey==='' || authorized.indexOf('Admin Console')==-1){
    return(<div>Awaiting authorization...</div>)

  //}else{
    return (
      //<UserContext.Provider value={{authKey: authKey, setAuthKey: setAuthKey, refreshKey: refreshKey, setRefreshKey: setRefreshKey, authorized: authorized}}>
         {
          //path==="Home"?<Home/>:
          //path==="TeamReview"?<TeamReview/>:
          //path==="DataManager"?<DataManager authKey={authKey} setAuthKey={setAuthKey} refreshKey={refreshKey} authorized={authorized} setRefreshKey={setRefreshKey}/>:
          //path==="FormManager" && authorized.indexOf('Admin Console - Form Manager')?<FormManager/>:
          //<></>
        }
      //</UserContext.Provider>
    )
  }  
  /*return (
    <div className="App">
    <Router>
      <AppRoutes />
    </Router>
    {// <GearBox/> 
    }
    </div>
  );*/
//}

export default App;
