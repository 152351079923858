import React, { useEffect, useState, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import "../serialSearch.css";
import Header from "./header";
import { setPathCookie } from "../services/fetcher";
import { UserContext } from "../index";
import { parseJwt, getHistory } from "../services/fetcher";
import { SearchTable } from "./serialSearch";
function Search() {
  const { authKey, setAuthKey, refreshKey, setRefreshKey, authorized } = useContext(UserContext);
  const [searchInput, setSearchInput] = useState();
  const parseKey = parseJwt(authKey);
  const [historyData, setHistoryData] = useState();
  // const [iframeContent, setIframeContent] = useState();
  // const { iframeContent } = useParams();

  useEffect(()=>{
    console.log('Getting History'+authKey,parseKey);
    getHistory(authKey, parseKey['email']).then(
      (data)=>{
        console.log(data);
        let data2Sort=JSON.parse(JSON.stringify(data.scanHistory))//data.scanHistory.slice();
        console.log(data2Sort);
        data2Sort.sort(function (a,b){
          console.log('comparing - '+(a.scannedAt<b.scannedAt),a,b);
          if(a.scannedAt>b.scannedAt){
            return -1;
          }else if(a.scannedAt<b.scannedAt){
            return 1;
          }
          return 0;
        })
        console.log(data2Sort);
        setHistoryData({scanHistory: data2Sort});
      }
    )
  },[authKey])

  const navigate = useNavigate();

  const handleChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (searchInput !== "") {
      setPathCookie(`/serialSearch/${searchInput}`);
      navigate(`/serialSearch/${searchInput}`);
    }
  };
  console.log('History: ',historyData)
  return (
    <>
    <Header/>
    <div className="container my-4">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-6">
          <form className="d-flex" onSubmit={handleSubmit}>
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              value={searchInput}
              onChange={handleChange}
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form>
        </div>
      </div>
      <h4 className="mt-5 row align-items-center justify-content-center">Search History</h4>
      <SearchTable data={historyData?.scanHistory} tableType={"history"}/>
      {
        //historyData.scanHistory.map((data)=>{
          //return (<><br/>Data: {data.description}</>)
        //})
      }
    </div>
    </>
  );
}

export default Search;
