import React,{useState, useEffect, useContext} from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Search from '../components/search';
import SerialSearch from '../components/serialSearch';
import Header from "../components/header";
import SerialData from '../components/serialData';
import GearBox from '../components/gearBox';
import FetchTest from '../components/fetchTest';
import App from '../App';
import { getToken, getUserPermissions, setPathCookie  } from './fetcher';
import { UserContext } from '../index';
// import Fetcher from './fetcher';
function CheckCode() {
  const {pathname, search, hash} = useLocation()
  const params=new URLSearchParams(search);
  return (
      params.get('code')
  )
}
//export const UserContext = React.createContext(null);

//making a change please work
function AppRouting() {
  //const currCode=CheckCode();
  const cookiePath=document.cookie.split("; ").find((row) => row.startsWith("tempPath="))?.split("=")[1];
  const navRoute=useNavigate();
  var {pathname, search, hash} = useLocation();
  const [loading, setLoading]=useState(true);
  const { authKey, setAuthKey, refreshKey, setRefreshKey, authorized, setAuthorized } = useContext(UserContext);
  //alert('AuthKey: '+authKey);

  const params=new URLSearchParams(search);
  const currCode=params.get('code');
  var ignore=false;

  useEffect(()=>{
    if(authKey==="" && currCode===null){
      const codeURL=process.env.REACT_APP_COGNITO+'authorize?response_type=code&client_id='+process.env.REACT_APP_CLIENTID+'&redirect_uri='+process.env.REACT_APP_BASEURL+'&state=STATE&scope=openid+email+profile'
      setPathCookie(pathname);
      window.location.replace(codeURL);
      ////return (<div>Authorization Required... Redirecting...</div>)
    }else if(authKey==='' && currCode!==null){
      if(ignore==false){
        getToken(currCode,setAuthKey,setRefreshKey).then(response=>{
          if(cookiePath!=pathname){
            //alert('Cookie: '+cookiePath+' / Path: '+pathname);
            navRoute(cookiePath);
            window.history.replaceState(null, "eSales Serial Search", cookiePath);
          }    
          setLoading(false);
        });
      }
      return ()=>{ignore = true}
    }else{
      console.log('There was a disturbance')
    }
  },[])

  useEffect(()=>{
    if(authKey!==undefined && authKey!==''){
      getUserPermissions(authKey,setAuthKey,refreshKey).then(response2=>{
        console.log('dropped in',response2)
        window.history.pushState('','eXpress App Portal','/')
        //registerDevice();        
        setAuthorized(response2.data);          
      })

    }
  },[authKey])  


  if(loading){
    return(<div>Loading...</div>)
  }else{
    return (
      <>
      {/* <Header/> */}
        <Routes>
          <Route exact path="/" element={<Search/>}/>
          <Route exact path="/search" element={<Search/>}/>
          <Route path="/fetchTest" element={<FetchTest/>}/>
          <Route exact path="/gearBox" element={<GearBox/>} />
          { 
            //<Route exact path="/" element={<Search/>} />
            //<Route exact path="/search" element={<Search/>} />
          }
          <Route path="/serialSearch/:searchInput" element={<SerialSearch/>} />
          <Route path="/serialData/:equipId" element={<SerialData/>} />
        </Routes>
      </>
    );
  }
}

export default AppRouting;